import { Container, Row, Col, Card } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PureBreadcrumbs from "../breadcrums";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { singleViewTicketAction, updateTicket } from "../../../store/actions/ticketAction";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import { Bounce, ToastContainer, toast } from "react-toastify";

function UpdateTicket(props) {
  const { t } = useTranslation();
  const { ticket_id } = useParams()
  const [severity, setSeverity] = useState('');
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageValidation, setImageValidation] = useState(false)
  const { token, access, userid, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const [redirect, setRedirect] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false);

  useEffect(() => {
    props.getSingleTicketData(ticket_id);
  }, []);

  const data = props.fetchSingleTicketDetails && props.fetchSingleTicketDetails.data;
  // console.log("data", data);
  const ticketSchema = Yup.object().shape({
    title: Yup.string().required('Title is required').max(60, 'Title must be at most 60 characters'),
    affected_module: Yup.string().required(' Affected moduel is Required'),
    ticket_type: Yup.string().required('Issue type is Required'),
    severity: Yup.string().required('Please select severity'),
    description: Yup.string().required('Description is Required'),
    createdby: Yup.string(),
    updatedby: Yup.string(),
    assignedto: Yup.string().required('Assign to is Required'),
    status: Yup.string().required('Status is Required'),
    attachments: Yup.mixed(),
  });
  const { register, handleSubmit, resetField, getValues, setValue, formState: { errors } } = useForm({ resolver: yupResolver(ticketSchema) });
  //  console.log("status: ", data.status)

  useEffect(() => {
    if (data) {
      setValue('title', data.title);
      setValue('affected_module', data.affected_module);
      setValue('ticket_type', data.ticket_type);
      setValue('description', data.description);
      setValue('createdby', data.createdby);
      setValue('updatedby', data.updatedby);
      setSeverity(data.severity)
      setValue('assignedto', data.assignedto);
      setValue('status', data.status);
      register("createdby", data.createdby);
    }
  }, [setValue, data, register])

  useEffect(() => {
    // this logic is for severity it is working on onchange event
    if (severity) {
      setSeverity(severity);
      setValue("severity", severity);
    }
  }, [setValue, severity])

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // console.log(file);

    if (file) {
      setSelectedFile(file);

      const reader = new FileReader();
      // console.log(reader)
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile(null);
      setImagePreviewUrl('');
    }
  };


  const handleSeverityChange = (e) => {
    // console.log(e.target.value)
    setSeverity(e.target.value)
  };

  const onSubmit = async data => {
    // console.log("data", data);
    const formData = new FormData();

    formData.append('affected_module', data.affected_module);
    formData.append('ticket_type', data.ticket_type);
    formData.append('severity', data.severity);
    formData.append('description', data.description);
    formData.append('createdby', data.createdby);
    formData.append('updatedby', userid);
    formData.append('assignedto', data.assignedto);
    formData.append('title', data.title);
    formData.append('role', role);
    formData.append('operator', organisation);
    formData.append('status', data.status);
    formData.append('ticket_id', ticket_id);
    if (data.attachments) {
      formData.append('attachments', data.attachments[0]);
    }

    // console.log('affected_module:', formData.get('affected_module'));
    // console.log('ticket_type:', formData.get('ticket_type'));
    // console.log('severity:', formData.get('severity'));
    // console.log('description:', formData.get('description'));
    // console.log('createdby:', formData.get('createdby'));
    // console.log('updatedby:', formData.get('updatedby'));
    // console.log('assignedto:', formData.get('assignedto'));
    // console.log('title:', formData.get('title'));
    // console.log('role:', formData.get('role'));
    // console.log('operator:', formData.get('operator'));
    // console.log('status:', formData.get('status'));
    // console.log('ticket_id:', formData.get('ticket_id'));
    // if (data.attachments) {
    //   console.log('attachments:', formData.get('attachments'));
    // }
    props.ticketUpdate(formData)
  }

  useEffect(() => {
    if (props.updateUser.statuscode === 200) {
      setIsToastVisible(true);
      toast.success('Ticket has been updated successfully', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          setIsToastVisible(false);
          setRedirect(true)
        },
      });
    }
  }, [props.updateUser]);

  if (redirect) {
    return <Redirect to='/dashboard/view_ticket' />;
  }

  // if (props.updateUser.statuscode === 200) {
  //   alert("Ticket has been updated successfully")
  //   return <Redirect to='/dashboard/view_ticket' />;

  // }
  // console.log("loading", props.loading);
  return (
    <Container fluid className="pb-4">
      {isToastVisible && <div className="overlay" />}
      <ToastContainer
        position="top-center"
        autoClose={20}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ top: "10%", width: "auto", zIndex: "9999" }}
      />
      <PureBreadcrumbs />
      <Row >
        <Col xs={12} md={12}>
          <Card className='mt-5 tariffcard'>

            <Card.Body>
              <h2 className='mb-5'>{t("Update Ticket")}</h2>
              <form autoComplete="off" onSubmit={e => e.preventDefault()} >

                <div className="form-floating">
                  <label htmlFor="title">{t("Title")}  <span className='mandatory-field'>*</span></label>
                  <input className="input  gray-border" type="text" name="title" id="floatingInputGridtitle" placeholder={t("Title")}  {...register("title")} disabled />
                </div><br />

                <div className="form-group">
                  <label htmlFor="affected_module">{t("Affected Module")} <span className='mandatory-field'>*</span></label>
                  <input className="input  gray-border" type="text" name="affected_module" id="floatingSelectGridAffectedModule" placeholder={t("Affected Moduel")} {...register("affected_module")} disabled />

                  {/* <input type="text" className={`form-control  gray-border ${errors.affected_module ? 'is-invalid' : ''}`} name="affected_module" id="floatingInputGridAffected_Module" placeholder="Affected Module" {...register("affected_module")} disabled /> */}
                </div>

                <div className="form-group">
                  <label htmlFor="ticket_type">{t("Issue Type")}<span className='mandatory-field'>*</span></label>
                  <div className="form-floating">
                    <select className={`form-select form-control ${errors.ticket_type ? 'is-invalid' : ''}`} name="ticket_type" id="floatingSelectGridIssueType" {...register("ticket_type")}>
                      <option value="">{t("Select issue type")}</option>
                      <option value="Bug">{t("Bug")}</option>
                      <option value="Comment">{t("Comment")}</option>
                      <option value="Feature Request">{t("Feature Request")}</option>
                    </select>

                  </div>
                </div>

                <div className="form-group">
                  <label>Severity<span className='mandatory-field'>*</span></label>
                  <label>&nbsp; {data.severity}</label>
                  <br />
                  <div className="btn-group btn-group-lg btn-group-toggle p-2" data-toggle="buttons">
                    <label className={`btn radio-btn  p-3 pr-3 btn-light ${errors.severity ? 'is-invalid' : ''} ${severity === 'Low' ? 'selected-severity-low' : ''}`}
                    >
                      <input
                        type="radio"
                        name="severity"
                        value="Low"
                        id="severityLow"
                        {...register('severity')}
                        onClick={(e) => handleSeverityChange(e)}
                      />
                      <span className="bulletLow"></span>
                      {t("Low")}
                    </label>

                    <label className={`btn radio-btn  p-3  pr-3  btn-light ${errors.severity ? 'is-invalid' : ''} ${severity === 'Medium' ? 'selected-severity-medium' : ''}`}
                    >
                      <input
                        type="radio"
                        name="severity"
                        value="Medium"
                        id="severityMedium"
                        {...register('severity')}
                        onClick={(e) => handleSeverityChange(e)}
                      />
                      <span className="bulletMedium"></span>
                      {t("Medium")}
                    </label>

                    <label className={`btn radio-btn  p-3  pr-3 btn-light ${errors.severity ? 'is-invalid' : ''} ${severity === 'High' ? 'selected-severity-high' : ''}`}
                    >
                      <input
                        type="radio"
                        name="severity"
                        value="High"
                        id="severityHigh"
                        {...register('severity')}
                        onClick={(e) => handleSeverityChange(e)}
                      />
                      <span className="bulletHigh"></span>
                      {t("High")}
                    </label>

                    <label className={`btn  radio-btn p-3  pr-3  btn-light ${errors.severity ? 'is-invalid' : ''} ${severity === 'Critical' ? 'selected-severity-critical' : ''}`}
                    >
                      <input
                        type="radio"
                        name="severity"
                        value="Critical"
                        id="severityCritical"
                        {...register('severity')}
                        onClick={(e) => handleSeverityChange(e)}
                      />
                      <span className="bulletCritical"></span>
                      {t("Critical")}
                    </label>
                  </div>

                </div>

                <div className="form-group">
                  <label htmlFor="assignedto">{t("Assign to")}.. <span className='mandatory-field'>*</span></label>
                  <div className="form-floating">
                    <input className="input  gray-border" type="text" name="assignedto" id="floatingSelectGridA" placeholder={t("Assign to")} {...register("assignedto")} disabled />
                    {/* <input type="text" className={`form-control  gray-border ${errors.assignedto ? 'is-invalid' : ''}`} name="assignedto" id="floatingInputGridassignedto" placeholder="Assigned To" {...register("assignedto")} disabled /> */}
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="attachments">{t("Attachment (Image only)")}</label>
                  <div className="custom-file">
                    <input
                      type="file"
                      accept="image/*"
                      className={`custom-file-input ${errors.attachments ? 'is-invalid' : ''}`}
                      name="attachments"
                      id="attachments"
                      {...register("attachments")}
                      onChange={(e) => handleFileChange(e)}
                    />
                    <label className="custom-file-label" htmlFor="attachments">
                      {selectedFile ? `You have selected 1 file: ${selectedFile.name}` : 'Choose file...'}
                    </label>
                    <div className="invalid-feedback">{errors.attachments?.message}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="attachments">{t("Attachment (Image only)")}</label>
                  <div className="">
                    <React.Fragment>
                      {data?.attachments !== "" ?

                        <img
                          src={data?.attachments}
                          className={`object-fit-contain videoContainer`}
                          alt={data?.attachments}
                        />
                        :
                        <b>{t("No advertisement available")}</b>
                      }
                    </React.Fragment>
                  </div>
                </div>

                <div className='row g-2 mt-3'>
                  <div className='col-md'>
                    <div className="form-floating">
                      <label htmlFor='floatingTextarea' >{t("Description")}<span className='mandatory-field'>*</span></label>
                      <textarea className={`form-control  ${errors.description ? 'is-invalid' : ''}`} placeholder={t("Description")} aria-label={t("Description")} name="description" id="floatingTextarea" style={{ "height": "100px" }}  {...register("description")}></textarea>
                    </div>
                  </div>
                </div>

                <div className="form-group  mt-3">
                  <label htmlFor="status">{t("Ticket Status")}</label>
                  <div className="form-floating">
                    <select className={`form-select form-control ${errors.status ? 'is-invalid' : ''}`} name="status" id="floatingSelectGridStatus" {...register("status")}>
                      <option value="Open">Open</option>
                      <option value="In Progress">In Progress</option>
                      <option value="Closed">Closed</option>
                    </select>

                  </div>
                </div>
                <div className='d-flex justify-content-end'>
                  <div className='d-flex justify-content-end' >
                    {props.loading ?
                      (<button className="btn btn-lg btn-success mt-4" type="button" disabled>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        &nbsp;
                        {t("Saving...")}
                      </button>)
                      :
                      (<button type="submit"
                        className="btn btn-lg btn-success mt-4"
                        onClick={handleSubmit(onSubmit)}
                        disabled={imageValidation}
                      >
                        {t("Update Ticket")}
                      </button>)
                    }
                  </div>
                </div>
              </form>
            </Card.Body>
          </Card>

        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = function (state) {
  return {
    loading: state.ticket.isLoading,
    fetchSingleTicketDetails: state.ticket.fetch_single_ticket,
    updateUser: state.ticket.update_ticket,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getSingleTicketData: (ticket_id) => dispatch(singleViewTicketAction(ticket_id)),
    ticketUpdate: (formData) => dispatch(updateTicket(formData)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdateTicket));

