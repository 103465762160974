import React from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import ApexCharts from "react-apexcharts";
import { fetchLifetimeEnergyRevenue } from "../../../../store/actions/insightAction";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

function EnergyVSRevenueGraph(props) {
  const currency = process.env.REACT_APP_CURRENCY;
  const { t } = useTranslation();
  useEffect(() => {

    setTimeout(() => {
      props.getTotal();
    }, 1500);
  }, []);

  // console.log("Graphloading",props.RevenueGraphLoading)
  // console.log("EnergyVSRevenueGraph", props.total);
  const options = {
    options: {
      chart: {
        id: "energy-vs-revenue-chart",
        toolbar: {
          show: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "60%",
          borderRadius: 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ["Total"],
      },
      yaxis: [
        {
          title: {
            text: ("Total Life-time Energy"),
          },
          // labels: {
          //   formatter: function (value) {
          //     return `${value.toFixed(2)}`;
          //   },
          // },
        },
        {
          opposite: true,
          title: {
            text: "Total Life-time Revenue",
          },

          labels: {
            formatter: function (value) {
              return `${currency} ${value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            },

          },
        },
      ],
      fill: {
        type: "gradient",
        gradient: {
          type: "vertical",
          shadeIntensity: 0.5,
          opacityFrom: 1,
          opacityTo: 0.8,
          stops: [0, 100],
        },
      },
      legend: {
        position: "top",
      },
    },
    series: [
      {
        name: "Total Life-Time Consumption",
        data: [props.total && props.total.totalenergy],
      },
      {
        name: "Total Life-Time Revenue ",
        data: [props.total && props.total.totalrevenue],
      },
    ],

  };
  return (
    <div>
      <Card className="statuscard">
        <Card.Header>
          <Row>
            <Col xs={12} md={8}>
              <div className="text-left">{t('Life Time Energy VS Revenue Graph')}</div>
            </Col>
            <Col>
              <Col className="text-right">
                <div className="btn-group dropleft">
                  <button
                    className="btn btn-md"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="" title="Comparision Graph">
                      <BsThreeDotsVertical />
                    </i>
                  </button>
                  <div
                    className="dropdown-menu energy-revenue-graph-toolbar"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a className="dropdown-item" href="./energyrevenue">
                      {t("Report")}
                    </a>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Card.Header>
        <div>
          {props.RevenueGraphLoading ? <h3 style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "340px",
          }}> Loading data...</h3> :
            props.total.totalenergy > 0 || props.total.totalrevenue > 0 ? (

              <ApexCharts
                options={options.options}
                series={options.series}
                type="bar"
                height={340}
              />) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "340px",
                }}
              >
                <b>No data found </b>
              </div>
            )}
        </div>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    RevenueGraphLoading: state.insight.isRevenueGraphLoading,
    total: state.insight.lifetime_energy_revenue,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTotal: () => dispatch(fetchLifetimeEnergyRevenue()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnergyVSRevenueGraph);
