//Local Base Url
// export const BASE_URL = "http://localhost:7001";
// export const CHARGING_SERVICE_URL = "http://localhost:7003";
// export const STATION_URL = "http://localhost:7011";
// export const PREDICTIVE_URL = "http://localhost:7009";
// export const CUSTOMER_URL = "http://localhost:7005";
// export const WALLET_URL = "http://localhost:7012";
// export const PRICE_URL = "http://localhost:7010";
// export const ADVERTISE_URL = "http://localhost:7016";
// export const COMMAND_URL = "http://localhost:7021";
// export const CAMPAIGN_URL = "http://localhost:7017";
// export const FLEET_URL = "http://localhost:7018";
// export const OCPI_URL =  "http://localhost:7041";
// export const COMMUNICATION_URL =  "http://localhost:7042";
// export const LOAD_URL =  "http://localhost:7015";
// export const TICKET_SERVICE_URL = "http://localhost:7019";
// export const LOYALTY_URL = "http://localhost:7080";

//UAT Base Url

// export const BASE_URL = "https://uat.cms.gaadin.live";
// export const CHARGING_SERVICE_URL = "https://uat.cms.gaadin.live";
// export const STATION_URL = "https://uat.cms.gaadin.live";
// export const PREDICTIVE_URL = "https://uat.cms.gaadin.live";
// export const CUSTOMER_URL = "https://uat.cms.gaadin.live";
// export const WALLET_URL = "https://uat.cms.gaadin.live";
// export const PRICE_URL = "https://uat.cms.gaadin.live";
// export const ADVERTISE_URL = "https://uat.cms.gaadin.live";
// export const COMMAND_URL = "https://uat.cms.gaadin.live";
// export const CAMPAIGN_URL = "https://uat.cms.gaadin.live";
// export const FLEET_URL = "https://uat.cms.gaadin.live";
// export const OCPI_URL = "https://uat.cms.gaadin.live";
// export const COMMUNICATION_URL = "https://uat.cms.gaadin.live";
// export const LOAD_URL = "https://uat.cms.gaadin.live";
// export const TICKET_SERVICE_URL = "https://uat.cms.gaadin.live";
// export const LOYALTY_URL = "https://uat.cms.gaadin.live";

// UAT Hoppal Url

export const BASE_URL = "https://csms.hoppl.app";
export const CHARGING_SERVICE_URL = "https://csms.hoppl.app";
export const STATION_URL = "https://csms.hoppl.app";
export const PREDICTIVE_URL ="https://csms.hoppl.app";
export const CUSTOMER_URL ="https://csms.hoppl.app";
export const WALLET_URL = "https://csms.hoppl.app";
export const PRICE_URL = "https://csms.hoppl.app";
export const ADVERTISE_URL = "https://csms.hoppl.app";
export const COMMAND_URL = "https://csms.hoppl.app";
export const CAMPAIGN_URL = "https://csms.hoppl.app";
export const FLEET_URL = "https://csms.hoppl.app";
export const OCPI_URL = "https://csms.hoppl.app";
export const LOAD_URL = "https://csms.hoppl.app";
export const TICKET_SERVICE_URL = "https://csms.hoppl.app";
export const COMMUNICATION_URL = "https://csms.hoppl.app";
export const LOYALTY_URL = "https://csms.hoppl.app";

//Main,Common URL
export const GET_CITY_API = "/admin/secure/api/v1/cities"
export const GET_STATE_API = "/admin/secure/api/v1/states"
export const GET_COUNTRY_API = "/admin/secure/api/v1/countries"
export const GET_AMENITY_API = "/admin/secure/api/v1/amenities"
export const GET_ROLE_API = "/admin/secure/api/v1/roles"
export const GET_PRIVILEGE_API = "/admin/secure/api/v1/privileges"
export const GET_SUBPRIVILEGE_API = "/admin/secure/api/v1/sub_privileges"
export const FETCH_ALL_CITIES = "/admin/secure/api/v1/allcities"
export const FETCH_ALL_MAKER = "/admin/secure/api/v1/get_maker"
export const FETCH_ALL_MODEL = "/admin/secure/api/v1/get_model"

export const GET_NEW_PRIVILAGE = "/admin/secure/api/v1/privileges/all"

//Admin
export const SAVE_ACCESS_CONTROL_API = "/admin/secure/api/v1/createuser"
export const GET_ACCESS_CONTROL_API = "/admin/secure/api/v1/all"
export const GET_DASHBOARD_USER_DATA = "/admin/secure/api/v1/getuser"
export const UPDATE_ACCESS_CONTROL_API = "/admin/secure/api/v1/updateuseraccess"
export const CHANGE_USER_PASSWORD_API = "/admin/secure/api/v1/dashboard/changepass"
export const PERCENTAGE_CHANGE_API = "/admin/secure/api/v1/dashboard/change_percentage_admin"
export const ADMIN_STATUS_API = "/admin/secure/api/v1/fetch/status"
export const ADMIN_VALIDATE_STATUS_API = "/admin/secure/api/v1/update/userstatus"
export const GET_NOTIFICATION = "/communication/secure/api/v1/dashboard/fetch/notification"
export const POST_NOTIFICATION = "/communication/secure/api/v1/dashboard_read/notification"
export const SAVE_MARK_AS_ALL_READ = "/communication/secure/api/v1/dashboard/noti_markallread"
export const FETCH_STATE_CODE = "/admin/secure/api/v1/states"
export const SAVE_STATE_GROUP = "/admin/secure/api/v1/save/state_group"
export const UPDATE_STATE_GROUP = "/admin/secure/api/v1/update/state_group"
export const FETCH_ALL_STATE_GROUP = "/admin/secure/api/v1/fetch/state_group"
export const DELETE_USER_ACCOUNT_API = "/admin/secure/api/v1/deleteuser"
//Auth URL
export const GET_LOGIN_API = "/admin/api/v1/login"
export const FORGET_PASSWORD_API = "/admin/api/v1/forget"
export const GET_SEND_OTP_API = "/admin/api/v1/dashboard/otplogin"
export const GET_RESEND_OTP_API = "/admin/api/v1/dashboard/resendOTP"
export const VALIDATE_OTP = "/admin/api/v1/dashboard/validateotp"



//Insight
export const FETCH_TOTAL_EVSE_STATUS_API = "/station/secure/api/v1/charger/status"
export const FETCH_TOTAL_CHARGING_API = "/predictive/secure/api/v1/getsessions_sum"
export const FETCH_HRS_SESSION_API = "/predictive/secure/api/v1/hourly_session"
export const FETCH_TOTAL_CITY_SESSION_API = "/predictive/secure/api/v1/getcities_consumption"
export const FETCH_SESSION_TYPE_API = "/predictive/secure/api/v1/getsession_types"
export const FETCH_TOTAL_PASS_FAIL_SESSION_API = "/predictive/secure/api/v1/getsession_status"
export const FETCH_CITY_COMPARISION_API = "/charging/secure/api/v1/transaction/citycomparison"
export const FETCH_STATION_COMPARISION_API = "/charging/secure/api/v1/transaction/stationcomparison"
export const FETCH_TOTAL_STATION_SESSION_API = "/predictive/secure/api/v1/getstation_consumption"
export const FETCH_WEEKLY_SESSION_API = "/predictive/secure/api/v1/weekly_session"
export const FETCH_MONTHLY_SESSION_API = "/predictive/secure/api/v1/monthly_session"
export const FETCH_LIFETIME_DATA_API = "/predictive/secure/api/v1/lifetime/energyrevenue"
export const FETCH_DATERANGE_SESSION_TYPE = "/predictive/secure/api/v1/getsession_types/dateranges"
export const FETCH_DATERANGE_SESSION_STATUS = "/predictive/secure/api/v1/getsession_status/daterange"
export const FETCH_CUSTOMER_TOP5_SEARCH_PREDICTION = "/predictive/secure/api/v1/fetch/top5search"
export const FETCH_CUSTOMER_SEARCH_PREDICTION = "/predictive/secure/api/v1/fetch/all/customersearch"
export const FETCH_ENERGY_REVENUE_INSIGHT_GRAPH = "/predictive/secure/api/v2/daterange/getstation_consumption_revenue"
export const FETCH_HEATMAP_GRAPH = "/predictive/secure/api/v1/fetch/heatmap_revenue"
export const INSIGHT_PERCENTAGE_CHANGE_API = "/predictive/secure/api/v1/insight/changepercentage"

export const FETCH_LIFETIME_DAILY_LIST_API = "/predictive/secure/api/v1/lifetime/energyrevenue/dailyreport"
export const FETCH_LIFETIME_WEEKLY_LIST_API = "/predictive/secure/api/v1/lifetime/energyrevenue/weeklyreport"
export const FETCH_LIFETIME_MONTHLY_LIST_API = "/predictive/secure/api/v1/lifetime/energyrevenue/monthlyreport"
export const FETCH_COUNTRIES_CODE_API = "/admin/secure/api/v1/fetch/translation_countries"
export const FETCH_TRANSLATION_API = "/admin/api/v1/fetch/translation_dashboard"
//Customer
export const FETCH_CUSTOMER_LIST_API = "/customer/secure/api/v1/dashboard/all"
export const FETCH_ALL_RFID_API = "/customer/secure/api/v1/rfid/all"
export const SAVE_RFID_API = "/customer/secure/api/v1/rfid/add"
export const FETCH_SINGLE_CUSTOMER_DATA_API = "/customer/secure/api/v1/dashboard/getsinglecustomer"
export const UPDATE_CUSTOMER_API = "/customer/secure/api/v1/dashboard/driver/profile/update"
export const CUSTOMER_PERCENTAGE_CHANGE_API = "/customer/secure/api/v1/dashboard/change_percentage_customer"
export const CUSTOMER_FEEDBACK_API = "/station/secure/api/v1/dashboard/all_station_rating"
export const CUSTOMER_AUTHENTICATOIN_LOG_API = "/customer/secure/api/v1/fetch/customer_authentication"
export const CUSTOMER_ACTIVATE_ACCOUNT_API = "/customer/secure/api/v1/profile/activate"
export const CUSTOMER_DEACTIVATE_ACCOUNT_API = "/customer/secure/api/v1/profile/deactivate"
export const UPDATE_ADMIN_STATUS = "/station/secure/api/v1/dashboard/update_station_rating"
export const GET_ADMIN_STATUS = "/station/secure/api/v1/dashboard/fetchone_station_rating"
export const ACTIVATE_RFID = "/customer/secure/api/v1/rfid/update/status_active"
export const DEACTIVATE_RFID = "/customer/secure/api/v1/rfid/update/status_inactive"
export const UPDATE_RFID_API = "/customer/secure/api/v1/rfid/update"
export const SAVE_CUSTOMER_GROUP = "/station/secure/api/v2/create/dashboard/customer_group"
export const UPDATE_CUSTOMER_GROUP = "/station/secure/api/v2/update/dashboard/customer_group"
export const FETCH_ALL_CUSTOMER_GROUP = "/station/secure/api/v2/fetchall/dashboard/customer_group"
export const FETCH_SINGLE_CUSTOMER_GROUP_DATA = "/station/secure/api/v2/get/dashboard/customer_group"
export const ACTIVATE_GROUP_API = "/station/secure/api/v2/customer_group/status/active"
export const DEACTIVATE_GROUP_API = "/station/secure/api/v2/customer_group/status/inactive"
export const FETCH_RFID_LIST_API = "/customer/secure/api/v1/profile/fetchall_rfid"
export const CREATE_RFID_BINDING_WITH_CUSTOMER_API = "/customer/secure/api/v1/profile/add_rfid"
export const GUEST_USER_SIGNUP_API = "/customer/api/v1/signup"
export const FETCH_SINGLE_RFID_API = "/customer/secure/api/v1/rfid/get"

//Wallet
export const FETCH_ALL_WALLET_API = "/wallet/secure/api/v1/get_wallets"
export const FETCH_CUSTOMER_WALLET_API = "/wallet/secure/api/v1/wallet/fetch"
export const UPDATE_CUSTOMER_WALLET_API = "/wallet/secure/api/v1/update_amount"
export const SAVE_SUBSCRIPTION_API = "/wallet/secure/api/v1/create/subscription"
export const FETCH_ALL_SUBSCRIPTION = "/wallet/secure/api/v1/findall/subscription"
export const FETCH_SINGLE_SUBSCRIPTION = "/wallet/secure/api/v1/findone/subscription"
export const UPDATE_SUBSCRIPTION_API = "/wallet/secure/api/v1/update/subscription"
export const ACTIVATE_SUBSCRIPTION_API = "/wallet/secure/api/v1/activate/subscription"
export const DEACTIVATE_SUBSCRIPTION_API = "/wallet/secure/api/v1/deactivate/subscription"
export const FETCH_SINGLE_USER_SUBSCRIPTION_HISTORY = "/wallet/secure/api/v1/fetch/dashboard/transaction"


//Tariff
export const SAVE_TARIFF_API = "/tariff/secure/api/v1/save";
export const FETCH_ALL_TARIFF_API = "/tariff/secure/api/v1/all";
export const SAVE_ORG_API = "/tariff/secure/api/v1/organisation/save"
export const FETCH_REVENUE_SHARE = "/tariff/secure/api/v1/all/revenue_share_data"
export const UPDATE_ORG_API = "/tariff/secure/api/v1/organisation/update"
export const FETCH_ACTIVE_ORG_API = "/tariff/secure/api/v1/organisation/allactive";
export const FETCH_ALL_ORG_API = "/tariff/secure/api/v1/organisation/all";
export const FETCH_SINGLE_ORG_TARIFF = "/tariff/secure/api/v1/organisation/fetch";
export const FETCH_SINGLE_TARIFF_API = "/tariff/secure/api/v1/fetch";
export const UPDATE_TARIFF_API = "/tariff/secure/api/v1/update"
export const SAVE_ORG_PROMOTION_CODE = "/tariff/secure/api/v1/organisation/save/promotion"
export const SAVE_ORG_CORPORATE_CODE = "/tariff/secure/api/v1/organisation/save/corporatecode"
export const FETCH_ORG_PROMOTION_CODE = "/tariff/secure/api/v1//organisation/promotion/all"
export const FETCH_ORG_CORPORATE_CODE = "/tariff/secure/api/v1/organisation/corporatecode/all"
export const FETCH_ALL_CURRENCY_API = "/tariff/secure/api/v1/fetchall/currency"
export const FETCH_ALL_CHARGING_UNIT_API = "/tariff/secure/api/v1/fetchall/chargingunit"
export const FETCH_ALL_TARIFF_TYPE_API = "/tariff/secure/api/v1/fetchall/tarifftype"
export const TARIFF_PERCENTAGE_CHANGE_API = "/station/secure/api/v2/dashboard/change_percentage_tariff"
export const FETCH_TARIFF_NAME = "/station/secure/api/v2/price/fetchexists"
export const FETCH_ORGANISATION_EXISTS = "/tariff/secure/api/v1/fetch/organisationexist"
export const FETCH_ALL_ORG_PERCENTAGE_API = "/tariff/secure/api/v1/dashboard/percentage_sub_cpo"
export const FETCH_STATION_TIME_SLOT_NAME = "/station/secure/api/v2/price_timeslot/station_exist"
export const FETCH_COUNTRY_CODE_API = "/tariff/secure/api/v1/fetchall/country_code"

//v2

export const CREATE_NEW_TIME_SLOT_API = "/station/secure/api/v2/price/create"
export const UPDATE_NEW_TIME_SLOT_API = "/station/secure/api/v2/price/update"
export const FETCH_NEW_TIME_SLOT_API = "/station/secure/api/v2/price/fetch"
export const FETCH_ALL_NEW_TIME_SLOT_API = "/station/secure/api/v2/price/fetch/all"
export const FETCH_ACTIVE_TARIFF_API = "/station/secure/api/v2/tariff/allactive"
export const FETCH_ALL_EVSE_API = "/station/secure/api/v2/station/all"
export const FETCH_ALL_LOCATION_NAME_EVSEID = "/station/secure/api/v2/station/fetchexist"
export const FETCH_ALL_EVSEID = "/station/secure/api/v2/station/evseidexist"
export const SAVE_NEW_TIME_SLOT_API = "/station/secure/api/v2/create/price_timeslot"
export const FETCH_ALL_NEW_TIME_SLOT = "/station/secure/api/v2/fetchall/price_timeslot"
export const FETCH_SINGLE_TIME_SLOT_TARIFF = "/station/secure/api/v2/fetch/price_timeslot"
export const UPDATE_NEW_TIME_SLOT = "/station/secure/api/v2/update/price_timeslot"
export const DELETE_API_BOOKING = "/station/secure/api/v1/dashboard/cancle_booking"
export const FETCH_BOOKING_CHARGER_SLOTS_API = "/station/secure/api/v1/dashboard/getchargerbookingslots"
export const DELETE_BOOKING_API = "/station/secure/api/v1/dashboard/delete_booking_master"

export const ACTIVATE_TARIFF = "/station/secure/api/v2/price/status/active"
export const ACTIVATE_TARIFF_TIME_SLOT = "/station/secure/api/v2/timeslot/status/active"
export const DEACTIVATE_TARIFF = "/station/secure/api/v2/price/status/inactive"
export const DEACTIVATE_TARIFF_TIME_SLOT = "/station/secure/api/v2/timeslot/status/inactive"

export const ACTIVATE_PROMOTION = "/tariff/secure/api/v1/organisation/update/promotion_status_active"
export const DEACTIVATE_PROMOTION = "/tariff/secure/api/v1/organisation/update/promotion_status_inactive"

export const ACTIVATE_CORPORATE_CODE = "/tariff/secure/api/v1/organisation/update/corporatecode_status_active"
export const DEACTIVATE_CORPORATE_CODE = "/tariff/secure/api/v1/organisation/update/corporatecode_status_inactive"

export const UPDATE_PROMOTION_CODE = "/tariff/secure/api/v1/organisation/update/promotion"
export const UPDATE_CORPORATE_CODE = "/tariff/secure/api/v1/organisation/update/corporatecode"

//Station Service URL
export const SAVE_EVSE_API = "/station/secure/api/v1/create"
export const SAVE_CONFIGURED_API = "/station/secure/api/v1/ocpp/noti_error/createalert"
export const SAVE_STATION_GROUP_API = "/station/secure/api/v2/dashboard/create_station_group"
export const FETCH_ALL_STATION_GROUP_API = "/station/secure/api/v2/dashboard/fetchall_station_group"
export const FETCH_SINGLE_STATION_GROUP_API = "/station/secure/api/v2/dashboard/fetchone_station_group"
export const UPDATE_STATION_GROUP_API = "/station/secure/api/v2/dashboard/update_station_group"
export const ACTIVATE_STATION_GROUP_API = "/station/secure/api/v2/station_group/status/active"
export const DEACTIVATE_STATION_GROUP_API = "/station/secure/api/v2/station_group/status/inactive"
export const SAVE_STATION_GROUP_WITH_TARIFF_API = "/station/secure/api/v2/create/tariff_station_groups"
export const ACTIVATE_STATION_GROUP_WITH_TARIFF_API = "/station/secure/api/v2/tariff_station_groups/status/active"
export const DEACTIVATE_STATION_GROUP_WITH_TARIFF_API = "/station/secure/api/v2/tariff_station_groups/status/inactive"
export const FETCH_ALL_STATION_GROUP_WITH_TARIFF = "/station/secure/api/v2/fetchall/tariff_station_groups"
export const FETCH_SINGLE_STATION_GROUP_WITH_TARIFF = "/station/secure/api/v2/get/tariff_station_groups"
export const UPDATE_STATION_GROUP_WITH_TARIFF = "/station/secure/api/v2/update/tariff_station_groups"

//export const FETCH_ALL_EVSE_API = "/station/secure/api/v1/all";
export const FETCH_EVSE_SUB_NETWORK_API = "/station/secure/api/v1/network/type";
export const FETCH_EVSE_NETWORK_API = "/station/secure/api/v1/network";
export const TRIGGER_STATION_EVSE_STATUS_API = "/station/secure/api/v1/dashboard/charger/status";
export const LOAD_OCPP_NOTI_API = "/commands/secure/api/v1/dashboard/ocpp/noti_error";
export const FETCH_SINGLE_EVSE_API = "/station/secure/api/v1/station"
export const UPDATE_EVSE_API = "/station/secure/api/v1/update"
export const LOAD_BOOTNOTIFICATION_API = "/commands/secure/api/v1/dashboard/ocpp/boot_noti_details"
export const ACTIVATE_STATION = "/station/secure/api/v1/dashboard/station/operative"
export const DEACTIVATE_STATION = "/station/secure/api/v1/dashboard/station/inoperative"
export const FETCH_ALL_PARKING_TYPE_API = "/station/secure/api/v1/dashboard/fetchall/parkingtype"
export const FETCH_ALL_CONNECTOR_TYPE_API = "/station/secure/api/v1/fetchall/connectortype"
export const FETCH_ALL_POWER_RANGE_API = "/station/secure/api/v1/dashboard/fetchall/powerrange"
export const FETCH_ALL_CHARGER_STATUS_API = "/station/secure/api/v1/dashboard/fetchall/chargerstatus"
export const EVSE_PERCENTAGE_CHANGE_API = "/station/secure/api/v1/dashboard/station_change_percentage"
export const TIME_MASTER_API = "/station/secure/api/v1/all/bookinghours"
export const FETCH_EMAIL_DURATION_API = "/station/secure/api/v1/ocpp/email_duration/dropdown"
export const FETCH_PREVIOUS_EMAIL_CONFIG_API = "/station/secure/api/v1/ocpp/noti_error/single"
export const ALL_DAYS_API = "/station/secure/api/v2/timeslot/days/dropdown"
export const STATION_DELETE_API = "/station/secure/api/v2/station/delete"

//V2

export const CREATE_NEW_STATION_V2_API = "/station/secure/api/v2/station/create"
export const UPDATE_NEW_STATION_V2_API = "/station/secure/api/v2/station/update"
export const FETCH_SINGLE_EVSE_V2_API = "/station/secure/api/v2/station/fetch"
export const SAVE_ROAMING_STATIONS_API = "/station/secure/api/v2/ocpi/station/update"
export const CLEARED_CACHE_API = "/station/secure/api/v2/station/redis/refresh"

//Booking
export const FETCH_CUSTOMER_BOOKING_DATA = "/station/secure/api/v1/dashboard/getalluserbooking"
export const FETCH_CHARGER_BOOKING_SLOTS = "/station/secure/api/v1/dashboard/getbookingmaster"
export const SAVE_CHARGER_MASTER_SLOTS = "/station/secure/api/v1/dashboard/savebookingmaster"
export const FETCH_BOOKING_COUNT = "/station/secure/api/v1/sixmonth/bookingcount"
export const FETCH_BOOKING_PERCENTAGE = "/station/secure/api/v1/fetch/changepercentage/booking"
export const UPDATE_BOOKING_DATA_API = "/station/secure/api/v1/dashboard/update_booking_master"

//Report Service
export const FETCH_DAILY_REPORT_API = "/predictive/secure/api/v1/report/fetch"
export const FETCH_ALL_REPORT_API = "/predictive/secure/api/v1/report/all"
export const FETCH_CHARGER_REPORT_API = "/charging/secure/api/v1/fetchall/charger_energy"
export const REPORT_PERCENTAGE_CHANGE_API = "/predictive/secure/api/v1/report/changepercentage/revenue"
export const REVENUE_SHARING_LIST_API = "/predictive/secure/api/v1/revenue_sharing_report"
export const REVENUE_TOP10_TRANSACTION_API = "/predictive/secure/api/v1/top10/report"
export const FETCH_PLUG_UNPLUG_REPORT = "/commands/secure/api/v1/dashboard/plug_unplug/report/all"

//charging Service
export const RELEASE_TRANSACTION_API = "/charging/secure/api/v1/transaction/release"
export const FETCH_LIVE_CHARGING_API = "/charging/secure/api/v1/livecharging"
export const FETCH_LIVE_CHARGING_GRAPH_API = "/charging/secure/api/v1/analytics/livecharging"
export const FETCH_USER_CHARGING_HISTORY_API = "/charging/secure/api/v1/history/get"

//Advertisment service
export const CREATE_ADVERTISEMENT_API = "/advertise/secure/api/v1/create";
export const UPDATE_ADVERTISEMENT_API = "/advertise/secure/api/v1/update_avdertisement";
export const FETCH_ADVERTISEMENT_API = "/advertise/secure/api/v1/fetch"
export const FETCH_SIX_MONTH_GRAPH_API = "/advertise/secure/api/v1/fetch/sixmonthadvertisecount"
export const FETCH_SINGLE_ADVERTISE_API = "/advertise/secure/api/v1/get/single_advertisement"
export const FETCH_ACTIVATE_ADVERTISMENT_API = "/advertise/secure/api/v1/status/advertisement_status_active"
export const FETCH_DEACTIVATE_ADVERTISMENT_API = "/advertise/secure/api/v1/status/advertisement_status_inactive"
export const FETCH_ADVERTISEMENT_OVERVIEW_DATA = "/advertise/secure/api/v1/advertise/total"
export const FETCH_ADVERTISEMENT_DROPDOWN_API = "/advertise/secure/api/v1/fetchapp/advertise"
export const FETCH_ADVERTISE_CLICK_COUNT_API = "/advertise/secure/api/v1/advertise/thirtydays/clickcount"
export const FETCH_ALL_ADVERTISE_REPORT_API = "/advertise/secure/api/v1/advertise/report"


// Command service
export const CHANGE_AVAILABILITY_API = "/commands/secure/api/v1/change_availability"
export const TRIGGER_RESET_API = "/commands/secure/api/v1/reset"
export const TRIGGER_CLEAR_CACHE_API = "/commands/secure/api/v1/clear_cache"
export const UNLOCK_CONNECTOR_API = "/commands/secure/api/v1/unlock_connector"
export const TIGGER_GET_DIAGNOSTICS = "/commands/secure/api/v1/get_diagnostics"
export const TIGGER_MESSAGE = "/commands/secure/api/v1/trigger_message"
export const TIGGER_CLEAR_CHARGING_PROFILE = "/commands/secure/api/v1/clear_charging_profile"
export const GET_LOCAL_LIST_VERSION = "/commands/secure/api/v1/get_local_list_version"
export const UPDATE_FIRMWARE = "/commands/secure/api/v1/update_firmware"
export const GET_COMPOSITE_SCHEDULE = "/commands/secure/api/v1/get_composite_schedule"
export const TRIGGER_RESERVE_NOW = "/commands/secure/api/v1/reserve_now"
export const TRIGEGR_CANCEL_RESERVATION = "/commands/secure/api/v1/cancel_reservation"
export const GET_CONFIGURATION = "/commands/secure/api/v1/get_configuration"
export const CHANGE_CONFIGURATION = "/commands/secure/api/v1/change_configuration"
export const OCPP_LOG_API = "/commands/secure/api/v1/report/all"
export const CHARGER_CONFIG_DATA_API = "/commands/secure/api/v1/report/fetch_charger_configuration"
export const FETCH_CONFIG_DATA_API = "/commands/secure/api/v1/fetch_configuration"
export const SAVE_CONFIG_API = "/commands/secure/api/v1/save_configuration"

//campaign service apis
export const CREATE_EMAIL_CAMPAIGN_API = "/campaign/secure/api/v1/create/email"
export const UPDATE_EMAIL_CAMPAIGN_API = "/campaign/secure/api/v1/update/email"
export const CREATE_PUSH_NOTIFICATION_CAMPAIGN_API = "/campaign/secure/api/v1/create/pushnotification"
export const UPDATE_PUSH_NOTIFICATION_CAMPAIGN_API = "/campaign/secure/api/v1/update/pushnotification"
export const CREATE_SMS_CAMPAIGN = "/campaign/secure/api/v1/create/smsnotification"
export const UPDATE_SMS_CAMPAIGN = "/campaign/secure/api/v1/update/smsnotification"
export const FETCH_EMAIL_CAMPAIGN_API = "/campaign/secure/api/v1/fetch/emailcampaign"
export const FETCH_SMS_CAMPAIGN_API = "/campaign/secure/api/v1/fetch/smsnotification"
export const FETCH_PUSH_CAMPAIGN_API = "/campaign/secure/api/v1/fetch/pushnotification"
export const GET_MONTHLY_EMAIL_API = "/campaign/secure/api/v1/fetch/monthlyemail"
export const GET_MONTHLY_SMS_API = "/campaign/secure/api/v1/fetch/monthlysms"
export const GET_MONTHLY_PUSH_API = "/campaign/secure/api/v1/fetch/monthlypushnotification"
export const CAMPAIGN_PERCENTAGE_CHANGE = "/campaign/secure/api/v1/dashboard/change_percentage_campaign"
export const ACTIVATE_SMS_API = "/campaign/secure/api/v1/status/smsnotification_status_active"
export const DEACTIVATE_SMS_API = "/campaign/secure/api/v1/status/smsnotification_status_inactive"
export const ACTIVATE_NOTIFICATION_API = "/campaign/secure/api/v1/status/pushnotification_status_active"
export const DEACTIVATE_NOTIFICATION_API = "/campaign/secure/api/v1/status/pushnotification_status_inactive"
export const VIEW_SINGLE_SMS_API = "/campaign/secure/api/v1/get/single_smsnotification"
export const VIEW_SINGLE_NOTIFICATION_API = "/campaign/secure/api/v1/get/single_pushnotification"
export const VIEW_SINGLE_EMAIL_API = "/campaign/secure/api/v1/get/single_email"
export const ACTIVATE_EMAIL = "/campaign/secure/api/v1/status/email_status_active"
export const DEACTIVATE_EMAIL = "/campaign/secure/api/v1/status/email_status_inactive"

//Fleet
export const FETCH_LOCATION_API = "/fleet/secure/api/v1/dashboard/fetch/locations"
export const FETCH_VEHICLE_LOCATION_API = "/fleet/secure/api/v1/dashboard/fetch/vehicle_location"
export const FETCH_HEAVY_VEHICLE_API = "/fleet/secure/api/v1/dashboard/fetch/heavy_vehicles"
export const FETCH_LIGHT_VEHICLE_API = "/fleet/secure/api/v1/dashboard/fetch/light_vehicles"
export const SAVE_VEHICLE_CHARGING_PRIORITY_API = "/fleet/secure/api/v1/set/vehicle/charging_priority"
export const SAVE_FLEET_OWNER_API = "/fleet/secure/api/v1/save/fleetOwner"
export const FETCH_FLEET_VEHICLEBYOPERATOR_API = "/fleet/secure/api/v1/fetch/vehiclebyoperator"
export const FETCH_FLEET_LIVE_CHARGING = "/charging/secure/api/v1/fleet/livecharging"
export const FETCH_ALL_FLEET_NAME = "/fleet/secure/api/v1/fetch/fleetOwner"
export const FETCH_FLEET_INSIGHTS = "/fleet/secure/api/v1/fetch/fleetInsights"
export const FETCH_FLEET_REPORT = "/fleet/secure/api/v1/fetch/transaction/report"
export const FETCH_FLEET_CONSUMPTION_GRAPH = "/fleet/secure/api/v1/fleet/monthlysession"
export const FETCH_FLEET_REVENUE_GRAPH = "/fleet/secure/api/v1/fleet/getrevenueconsumption"
export const FETCH_VEHICLE_DATA = "/fleet/secure/api/v1/fetchall/vehicle"
export const FETCH_FLEET_CHARGING = "/fleet/secure/api/v1/fetch/top10transactions"

// New Fleet API URl

export const FETCH_FLEET_NAME = "/fleet/secure/api/v1/fetch/organisation/fleet_list"
export const FETCH_VIN_NO_API = "/fleet/secure/api/v1/fetch/fleet/vin"
export const FETCH_VIN_DATA = "/fleet/secure/api/v1/get/fleet/vehicle"

//OCPI 
export const SAVE_ROAMING_PARTNER = "/ocpi/secure/api/v1/save/roamingpartner"
export const VIEW_ALL_ROAMING_PARTNER = "/ocpi/secure/api/v1/fetchall/roamingpartner"
export const VIEW_SINGLE_ROAMING_PARTNER = "/ocpi/secure/api/v1/fetch/single/roamingpartner"
export const UPDATE_ROAMING_PARTNER = "/ocpi/secure/api/v1/update/roamingpartner"
export const FETCH_ALL_OCPI_API = "/ocpi/secure/api/v1/fetch/partner/locations"
export const FETCH_SINGLE_OCPI_VIEW_API = "/ocpi/secure/api/v1/get/partner/location"
export const ACTIVATE_ROAMING_API = "/ocpi/secure/api/v1/activate/roamingpartner"
export const DEACTIVATE_ROAMING_API = "/ocpi/secure/api/v1/deactivate/roamingpartner"
export const FETCH_PARTNER_TARIFF_API = ""

//Load Management
export const FETCH_LOAD_STATION_LIST = "/load/secure/api/v1/fetch_all_station_list"
export const FETCH_LOAD_CHARGER_LIST = "/load/secure/api/v1/fetch_station_live_sessionloads"
export const FETCH_LOAD_CHARGER_STATUS = "/load/secure/api/v1/fetch_station_insights"
export const FETCH_LIVE_LOAD = "/load/secure/api/v1/fetch_live_load_station"
export const FETCH_INDIVIDUAL_CHARGER_TOTAL_LIVE_LOAD = "/load/secure/api/v1/fetch_station_live_session"
export const FETCH_LOAD_TYPE_API_LIST = "/load/secure/api/v1/fetch/loadmanagement_type"
export const FETCH_LOAD_CHARGING_PROFILE_API_LIST = "/load/secure/api/v1/fetch/charging_profile_list"
export const FETCH_LOAD_CHARGING_PROFILE_KIND_API = "/load/secure/api/v1/fetch/charging_profile_kind"
export const FETCH_LOAD_CHARGING_PROFILE_LIMT_API = "/load/secure/api/v1/fetch/limit_list"
export const UPDATE_LOAD_CHARGING_PROFILE_API = "/load/secure/api/v1/update_set_charging_profile"
export const UPDATE_LOAD_DYNAMIC_MANAGEMENT_API = "/load/secure/api/v1/update_station_dynamic_load_rule"

// new load management API
export const SAVE_LOAD_CAPACITY_API = "/load/secure/api/v1/station/load_details"
export const FETCH_SINGLE_STATION_LOAD_API = "/load/secure/api/v1/fetch/station/load_details"
export const CHARGER_LOAD_LIST_API = "/load/secure/api/v1/station/evses_list"
export const CURRENT_LOAD_GRAPH_API = "/load/secure/api/v1/current/load_graph"

// Ticket Service Management
export const CREATE_TICKET = "/ticket/secure/api/v1/createticket"
export const FETCH_ALL_TICKET = "/ticket/secure/api/v1/allticket"
export const FETCH_SINGLE_TICKET = "/ticket/secure/api/v1/fetchticket"
export const UPDATE_TICKET_API = "/ticket/secure/api/v1/update_ticket"

// stripe
export const FETCH_STRIPE_BALANCE = "/wallet/secure/api/v1/stripe/balance"
export const FETCH_STRIPE_COUNTRY = "/wallet/secure/api/v1/stripe/country_specs"
export const FETCH_ALL_CONNECT_ACCOUNT = "/wallet/secure/api/v1/stripe/account/fetchall"
export const VIEW_SINGLE_CONNECT_ACCOUNT = "/wallet/secure/api/v1/stripe/accounts/get"
export const DELETE_SINGLE_CONNECT_ACCOUNT = "/wallet/secure/api/v1/stripe/account/delete"
export const CREATE_STRIPE_CUSTOMER = "/wallet/secure/api/v1/stripe/accounts/create"
export const CREATE_STRIPE_CUSTOMER_TOKEN = "/wallet/secure/api/v1/stripe/tokens"
export const CREATE_STRIPE_CUSTOMER_EXT_ACCOUNT = "/wallet/secure/api/v1/stripe/accounts/external_accounts"
export const GET_ALL_TRANSFER_DETAILS = "/wallet/secure/api/v1/stripe/transfers/fetchall"
export const GET_SINGLE_TRANSFER_DETAILS = "/wallet/secure/api/v1/transfers/stripe/get"
export const TRIGGER_ONBOARDING_EMAIL = "/wallet/secure/api/v1/stripe/account/onboarding"
export const SAVE_STRIPE_PASSWORD_API = "/admin/secure/api/v1/stripe/save_password"
export const VALIDATE_STRIPE_PASSWORD_API = "/admin/secure/api/v1/stripe/validate_user"
export const STRIPE_TRANSFER_API = "/wallet/secure/api/v1/stripe/transfers"
export const STRIPE_PAYMENT_INTENT_API = "/wallet/secure/api/v1/stripe/payment_intents"
export const STRIPE_CONFIRM_PAYMENT_INTENT_API = "/wallet/secure/api/v1/stripe/payment_intents/confirm"
export const CREATE_MANUAL_RECONCILATION_API = "/wallet/secure/api/v1/create/manual_reconciliation"
export const UPDATE_MANUAL_RECONCILATION_API = "/wallet/secure/api/v1/update/manual_reconciliation"
export const FETCH_ALL_RECONCILATION_API = "/wallet/secure/api/v1/fetch/all/manual_reconciliation"
export const FETCH_SINGLE_RECONCILATION_API = "/wallet/secure/api/v1/fetch/single/manual_reconciliation"

// Loyalty service

// rule
export const SAVE_LOYALTY_RULE = "/loyalty/secure/api/v1/create/new_rule"
export const FETCH_ALL_LOYALTY_RULE = "/loyalty/secure/api/v1/fetchall/rule"
export const FETCH_SINGLE_LOYALTY_RULE = "/loyalty/secure/api/v1/get/rule"
export const UPDATE_LOYALTY_RULE = "/loyalty/secure/api/v1/update/rule"
export const ACTIVATE_LOYALTY_RULE = "/loyalty/secure/api/v1/update/rule/status_true"
export const DEACTIVATE_LOYALTY_RULE = "/loyalty/secure/api/v1/update/rule/status_false"
export const RULE_TYPE_DROPDOWN = "/loyalty/secure/api/v1/fetch/dropdown/rules"

// program
export const SAVE_LOYALTY_PROGRAM = "/loyalty/secure/api/v1/create/new_program"
export const FETCH_ALL_LOYALTY_PROGRAM = "/loyalty/secure/api/v1/fetchall/program"
export const FETCH_SINGLE_LOYALTY_PROGRAM = "/loyalty/secure/api/v1/get/program"
export const UPDATE_LOYALTY_PROGRAM = "/loyalty/secure/api/v1/update/program"
export const ACTIVATE_PROGRAM_API = "/loyalty/secure/api/v1/update/program/status_true"
export const DEACTIVATE_PROGRAM_API = "/loyalty/secure/api/v1/update/program/status_false"

// redeem
export const SAVE_LOYALTY_REDEEM_API = "/loyalty/secure/api/v1/create/redemption_rule"
export const FETCH_ALL_REDEEM_API = "/loyalty/secure/api/v1/fetchall/redemption_rule"
export const FETCH_SINGLE_REDEEM_API = "/loyalty/secure/api/v1/get/redemption_rule"
export const UPDATE_LOYALTY_REDEEM = "/loyalty/secure/api/v1/update/redemption_rule"
export const ACTIVATE_LOYALTY_REDEEM = "/loyalty/secure/api/v1/update/redemption/status_true"
export const DEACTIVATE_LOYALTY_REDEEM = "/loyalty/secure/api/v1/update/redemption/status_false"
export const REDEEM_RULE_TYPE_DROPDOWN = "/loyalty/secure/api/v1/fetch/dropdown/redemption_rules"

// redeem customer

export const FETCH_ALL_CARD_API = "/loyalty/secure/api/v1/fetchall/total/customer_point_details"
export const FETCH_MONTHLY_GRAPH_DATA_API = "/loyalty/secure/api/v1/fetchall/monthly/customer_point_details"
export const FETCH_ALL_CUSTOMER_HOLI = "/loyalty/secure/api/v1/fetchall/loyalty/customers_list"
export const FETCH_SINGLE_GRAPH_API = "/loyalty/secure/api/v1/get/monthly/customer_point_details"
export const FETCH_TOTAL_CONSUMPTION_CARD_API = "/loyalty/secure/api/v1/get/available_points"
export const FETCH_SINGLE_CUSTOMER_REDEEMPTION_DATA_API = "/loyalty/secure/api/v1/fetch/point/transactions"
